import { parseQueryString, getQueryString } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export const gaToSensors = function (scenes) {
  const detault = {
    login: '正常',
    checkout: '购买中',
    coupons: '券包',
    wishlist: '收藏商品',
    '专题抽奖': '专题抽奖',
    '专题领券': '专题领券',
    livechat: '联系客服',
    account: '查看账户',
    freetrial: '免费试用',
    shareandearn: '全民营销拉新',
    googleonetapsign: 'googleonetapsign',
    other: '其他'
  }
  const opts = SHEIN_LOGIN.instanceActions.getOpts() || {}
  if (opts.sextra) return opts.sextra
  return detault[scenes] || '其他'
}

/**
 * 格式化登录组件or页面的来源
 * @param [string] scene 特殊场景，需要自定义scence,不取全局，比如：googleonetapsign
 * @returns 
 */
export function formatSaSences(scene = '') {
  if (typeof window == 'undefined') return ''
  if (scene) return scene
  const opts = SHEIN_LOGIN.instanceActions.getOpts() || {}
  if (opts.bextra) return opts.bextra
  if (opts.bi) return opts.bi

  var pathname = location.pathname
  var params = parseQueryString(location.search)
  var redirection = params.redirection || ''
  var direction = params.direction || ''
  var from_url = params.from
  var isLoginPage = /auth\/login$/.test(pathname)
  direction = direction.split('?')[0] // 去掉参数
  const [rwaRedirection] = redirection.split('?')

  const activity_sign = getQueryString({ key: 'activity_sign' }) || ''

  var matchUrl = pathname

  if (isLoginPage) { // 如果是在登录页面
    matchUrl = rwaRedirection
  }
  if (isLoginPage && !rwaRedirection) { // 如果没有重定向地址，m默认为login
    return 'login'
  }
  const userScences = {
    '/user/message': 'message',
    '/user/coupon': 'coupons',
    '/user/points': 'points',
    '/user/gift_card': 'giftcard',
    '/user/wallet': 'wallet',
    '/user/orders/list': 'orders',
    '/user/survey': 'survey',
    '/user/addressbook': 'address',
    '/user/notification': 'subscribe',
    '/user/prime': 'club',
  }
  if (userScences[rwaRedirection]) {
    return userScences[rwaRedirection]
  }
  if (/contact-us.html$/.test(matchUrl)) { //联系我们
    return 'livechat'
  }
  if (/cart$/.test(matchUrl) || from_url == 'emtpy_cart_module') { // 购物车
    return 'cart'
  }
  if (direction == 'nav') return 'login'
  if (matchUrl === '/user/wishlist') { // 登录页并且来自收藏列表
    return 'wishlist'
  }
  if (activity_sign) return activity_sign
  return 'other'
}

export function saSend (daId, params) {
  daEventCenter.triggerNotice({ daId, extraData: params } )
}
