import schttp from 'public/src/services/schttp'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { abtservice } from 'public/src/services/abt'
import { encryptOest } from '@shein-aidc/basis-oesthandle'

const getLogoList = (data) => data?.info?.logoList || []

const getAllAbtData = (newPosKeys, commonAbtData) => {
  return [...newPosKeys].reduce((acc, cur) => ({ ...acc, [cur]: commonAbtData?.[cur] || {} }), {})
}

const _getAbtInfo  = async () => {

  const newPosKeysForAbtService = [
    'PaymentSecurity',
    'PaymentRandomDiscount',
    'BinDiscountdiscount',
    'BinRandomDiscount',
    'tokenBinDiscountFrontWeb',
    'cardinstallmentPaymentFront',
    'checkCardinstallmentInfo'
  ]

  // eslint-disable-next-line @shein-aidc/abt/abt
  const allAbtData = await abtservice.getUserAbtResult({
    newPosKeys: newPosKeysForAbtService
  })

  // console.log('allAbtData==', allAbtData)
  
  return {
    ...getAllAbtData(newPosKeysForAbtService, allAbtData),
  }
}

async function _updateLtspcPciEnv (params = {}) {
  const { SiteUID, lang, currency, host, langPath, PLATFORM } = gbCommonInfo
  const ugid = UserInfoManager?.getSync?.({ key: 'ugid' })
  const oest = UserInfoManager.get({ key: 'oest' })
  const enOest = encryptOest(oest)

  const [
    { AuthToken: TokenAes } = {},
    { updateCs: ltspcCsrf } = {}
  ] = await Promise.all([
    UserInfoManager.get({ key: 'UserInfo', actionType: 'userInfo'  }),
    schttp({
      url: '/ltspc/updateCs',
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    })
  ])

  return schttp({
    url: '/ltspc/transformer/init',
    method: 'POST',
    data: {
      SiteUID,
      lang,
      AppCurrency: params?.currency || currency,
      TokenAes,
      _csrf: ltspcCsrf,
      originSite: host + langPath,
      siteFrom: PLATFORM === 'PWA' ? 'pwa' : 'pc',
      ugid,
      enOest
    }
  })
}

export async function fetchPrePayInfo () {

  await _updateLtspcPciEnv().catch(() => {})

  const [
    { value: payDomainLogo },
    { value: prePayLanguage },
    { value: checkoutLanguage },
    { value: supportInfoLanguage },
    { value: abtData },
    { value: prePaymentInfo }
  ] = await Promise.allSettled([
    schttp({
      url: '/api/checkout/payDomainLogo/get',
      params: {
        page_type: 4
      }
    }),
    schttp({
      url: '/api/common/language/get',
      params: {
        page: 'checkout_pre_pay'
      }
    }).then(({ language }) => language || {}),
    schttp({
      url: '/api/common/language/get',
      params: {
        page: 'checkout'
      }
    }).then(({ language }) => language || {}),
    schttp({
      url: '/api/common/language/get',
      params: {
        page: 'support_info'
      }
    }).then(({ language }) => language || {}),
    _getAbtInfo(),
    schttp({
      url: '/ltspc/api/prePay/info/query'
    }).then(data => data?.info || {})
  ])

  return {
    payDomainLogoList: getLogoList(payDomainLogo),
    language: { ...checkoutLanguage, ...prePayLanguage, ...supportInfoLanguage },
    abtData,
    prePaymentInfo
  }
}

export const fetchPrePayRoutingForCheckout = async (params) => {
  return schttp({
    url: '/api/pay/prePay/routingForCheckout/query',
    method: 'POST',
    data: params
  })
}

// 获取ddc流程表单提交的参数
export const queryPrePayDdcParams = async (params) => {
  return schttp({
    url: '/api/pay/prePay/memberPreCardToken/query',
    method: 'POST',
    data: params
  })
}

export const fetchInstallmentInfo = async (params) => {
  return schttp({
    url: '/api/checkout/installmentInfo/get',
    method: 'get',
    params
  })
}

export async function postUnPayComplete (data) {
  return schttp({
    method: 'post',
    url: '/ltspc/pay/unPay/complete',
    data
  })
}

// 单后前置路由信息
export async function queryUnPayPreRoutingInfo (data) {
  return schttp({
    method: 'post',
    url: '/ltspc/api/pay/preRoutingInfo/query',
    data
  })
}

export const updateLtspcPciEnv = _updateLtspcPciEnv

